import sortalphadownicon from "../../../../src/assets/sortalphadown.png";
import onenineicon from "../../../../src/assets//sortnumericdown.png";
import { ITooltipParams } from "ag-grid-community";

export const createIssueBatchColumnsConfig = [
  { value: "inreqd.LINE_NO", label: "Line No", type: "number" },
  { value: "inreqd.STOCK", label: "Stock Code", type: "text" },
  { value: "inreqd.LOCATION", label: "Location", type: "text" },
  { value: "inreqd.[DESC]", label: "Description", type: "text" },
  { value: "inreqd.REQ_QTY", label: "Available Qty", type: "number" },
  { value: "inreqd.REQ_QTY", label: "Req Qty", type: "number" },
  { value: "inreqd.BASE_EST", label: "Rate", type: "number" },
  { value: "inreqd.BASE_AMT", label: "Amount", type: "number" },
  { value: "inreqd.JOB_CODE", label: "Job Code", type: "text" },
  { value: "inreqd.MK_WONO", label: "Work Order No", type: "text" },
  { value: "inreqd.GL_ACCOUNT", label: "GL Account", type: "text" },
];

export const closeUnprocessedDetailFilterColumnConfig = [
  { value: "inreqd.LINE_NO", label: "Line No", type: "number" },
  { value: "inreqd.STOCK", label: "Stock Code", type: "text" },
  { value: "inreqd.LOCATION", label: "Location", type: "text" },
  { value: "inreqd.[DESC]", label: "Description", type: "text" },
  { value: "inreqd.REQ_QTY", label: "Req Qty", type: "number" },
  { value: "inreqd.BASE_EST", label: "Rate", type: "number" },
  { value: "inreqd.BASE_AMT", label: "Amount", type: "number" },
  { value: "inreqd.JOB_CODE", label: "Job Code", type: "text" },
  { value: "inreqd.MK_WONO", label: "Work Order No", type: "text" },
  { value: "inreqd.GL_ACCOUNT", label: "GL Account", type: "text" },

];
export const closeUnprocessedFilterColumnConfig = [
  { value: "inreqh.REQ", label: "Request No", type: "text" },
  { value: "inreqh.REQ_DATE", label: "Request Date", type: "date" },
  { value: "inreqh.NOTES", label: "Notes", type: "text" },
  { value: "inreqh.MULTI_EXPT", label: "Amount", type: "number" },
  { value: "rq.[DESC]", label: "Priority", type: "text" },
  { value: "inreqh.POST_STS", label: "Status", type: "text" },
  { value: "inreqh.ORG_AUTHID", label: "Requested By", type: "text" },
  { value: "inreqh.NXT_AUTHID", label: "Next Approver", type: "text" },
  { value: "inreqh.LST_AUTHID", label: "Last Approver", type: "text" },
  { value: "inreqh.RQH_DESC", label: "Instruction", type: "text" },
  { value: "inreqh.CONTACT", label: "Contact Person", type: "text" },
  { value: "inreqh.AUTHORIZE", label: "Auth Group", type: "text" },
  { value: "inreqh.AUTH_HIST", label: "Auth.History", type: "text" },
];

export const createIssueBatchFilterColumnsConfig = [
  { value: "inreqh.REQ", label: "Request No", type: "text" },
  { value: "inreqh.REQ_DATE", label: "Request Date", type: "date" },
  { value: "inreqh.NOTES", label: "Notes", type: "text" },
  { value: "inreqh.MULTI_EXPT", label: "Amount", type: "number" },
  { value: "rq.[DESC]", label: "Priority", type: "text" },
  { value: "inreqh.POST_STS", label: "Status", type: "text" },
  { value: "inreqh.ORG_AUTHID", label: "Requested By", type: "text" },
  { value: "inreqh.NXT_AUTHID", label: "Next Approver", type: "text" },
  { value: "inreqh.LST_AUTHID", label: "Last Approver", type: "text" },
  { value: "inreqh.RQH_DESC", label: "Instruction", type: "text" },
  { value: "inreqh.CONTACT", label: "Contact Person", type: "text" },
  { value: "inreqh.AUTHORIZE", label: "Auth Group", type: "text" },
  { value: "inreqh.AUTH_HIST", label: "Auth.History", type: "text" },
];
export const otherColumnsConfig = [
  { value: "inreqh.REQ", label: "Request No", type: "text" },
  { value: "inreqh.REQ_DATE", label: "Request Date", type: "date" },
  { value: "rq.[DESC]", label: "Priority", type: "text" },
  { value: "inreqh.RQH_DESC", label: "Instruction", type: "text" },
  { value: "inreqd.BASE_AMT", label: "Amount", type: "number" },
  { value: "inreqh.ORG_AUTHID", label: "Requested By", type: "text" },
  { value: "inreqh.AUTHORIZE", label: "Auth Group", type: "text" },
  { value: "inreqh.LST_AUTHID", label: "Last Approver", type: "text" },
  { value: "inreqh.NXT_AUTHID", label: "Authorized By", type: "text" },
  { value: "inreqh.CONTACT", label: "Contact Person", type: "text" },
  { value: "inreqh.NOTES", label: "Notes", type: "text" },
  { value: "inreqh.REQ_NOTES", label: "Req.Notes", type: "text" },
  { value: "inreqh.AUTH_HIST", label: "Auth.History", type: "text" },
];
export const approvestockdetailConfig = [
  { value: "inreqd.LINE_NO", label: "Line No", type: "number" },
  { value: "inreqd.STOCK", label: "Stock Code", type: "text" },
  { value: "inreqd.LOCATION", label: "Location", type: "text" },
  { value: "inreqd.[DESC]", label: "Description", type: "text" },
  { value: "inreqd.REQ_QTY", label: "Req Qty", type: "number" },
  { value: "inreqd.BASE_EST", label: "Rate", type: "number" },
  { value: "inreqd.BASE_AMT", label: "Amount", type: "number" },
  { value: "inreqd.JOB_CODE", label: "Job Code", type: "text" },
  { value: "inreqd.MK_WONO", label: "Work Order No", type: "text" },
  { value: "inreqd.GL_ACCOUNT", label: "GL Account", type: "text" },
];

export const approvestockConfig = [
  { value: "inreqh.REQ", label: "Request No", type: "text" },
  { value: "inreqh.REQ_DATE", label: "Request Date", type: "date" },
  { value: "inreqh.NOTES", label: "Notes", type: "text" },
  { value: "inreqh.MULTI_EXPT", label: "Amount", type: "number" },
  { value: "rq.[DESC]", label: "Priority", type: "text" },
  { value: "inreqh.POST_STS", label: "Status", type: "text" },
  { value: "inreqh.ORG_AUTHID", label: "Requested By", type: "text" },
  { value: "inreqh.NXT_AUTHID", label: "Next Approver", type: "text" },
  { value: "inreqh.LST_AUTHID", label: "Last Approver", type: "text" },
  { value: "inreqh.RQH_DESC", label: "Instruction", type: "text" },
  { value: "inreqh.CONTACT", label: "Contact Person", type: "text" },
  { value: "inreqh.AUTHORIZE", label: "Auth Group", type: "text" },
  { value: "inreqh.AUTH_HIST", label: "Auth.History", type: "text" },
];
const generateColumn = (id, col1, col2, col3, column, col4, iconIndex) => ({
  id,
  col1,
  col2,
  col3,
  column,
  col4,
  iconIndex,
});

const sortalphadown = sortalphadownicon;
const onenine = onenineicon;


export const approveColumnsConfig = [
  generateColumn("1", "⋮⋮⋮", false, "Request No", "inreqh.REQ", sortalphadown, 0),
  generateColumn("2", "⋮⋮⋮", false, "Request Date", "inreqh.REQ_DATE", onenine, 2),
  // generateColumn("3", "⋮⋮⋮", false, "Notes", "inreqh.REQ_NOTES", sortalphadown, 0),    //not required for sort
  generateColumn("4", "⋮⋮⋮", false, "Amount", "inreqh.MULTI_EXPT", onenine, 2),   
  generateColumn("5", "⋮⋮⋮", false, "Priority", "rq.[DESC]", sortalphadown, 0),
  generateColumn("6", "⋮⋮⋮", false, "Status", "inreqh.POST_STS", sortalphadown, 0),
  generateColumn("7", "⋮⋮⋮", false, "Requested By", "inreqh.ORG_AUTHID", sortalphadown, 0),
  generateColumn("8", "⋮⋮⋮", false, "Next Approver", "inreqh.NXT_AUTHID", sortalphadown, 0),
  generateColumn("9", "⋮⋮⋮", false, "Last Approver", "inreqh.LST_AUTHID", sortalphadown, 0),
  generateColumn("10", "⋮⋮⋮", false, "Instruction", "inreqh.RQH_DESC", sortalphadown, 0),
  generateColumn("11", "⋮⋮⋮", false, "Contact Person", "inreqh.CONTACT", sortalphadown, 0),
  generateColumn("12", "⋮⋮⋮", false, "Auth Group", "AUTHORIZE", sortalphadown, 0),
  // generateColumn("13", "⋮⋮⋮", false, "Auth.History", "inreqh.AUTH_HIST", sortalphadown, 0),  //not required for sort
];

export const approveDetailColumnsConfig = [
  generateColumn("1", "⋮⋮⋮", false, "Line No", "inreqd.LINE_NO", onenine, 2),
  generateColumn("2", "⋮⋮⋮", false, "Stock Code", "inreqd.STOCK", sortalphadown, 0),
  generateColumn("3", "⋮⋮⋮", false, "Location", "inreqd.LOCATION", sortalphadown, 0),
  generateColumn("4", "⋮⋮⋮", false, "Description", "inreqd.[DESC]", sortalphadown, 0),
  generateColumn("5", "⋮⋮⋮", false, "Req.Qty", "inreqd.REQ_QTY", onenine, 2),
  generateColumn("6", "⋮⋮⋮", false, "Rate", "inreqd.BASE_EST", onenine, 2),
  generateColumn("7", "⋮⋮⋮", false, "Amount", "inreqd.BASE_AMT", onenine, 2),
  generateColumn("8", "⋮⋮⋮", false, "Job Code", "inreqd.JOB_CODE", sortalphadown, 0),
  generateColumn("9", "⋮⋮⋮", false, "Work Order No", "inreqd.MK_WONO", sortalphadown, 0),
  generateColumn("10", "⋮⋮⋮", false, "GL Account", "inreqd.GL_ACCOUNT", sortalphadown, 0),
];

export const createIssueColumnsConfig = [
  generateColumn("1", "⋮⋮⋮", false, "Request No", "inreqh.REQ", sortalphadown, 0),
  generateColumn("2", "⋮⋮⋮", false, "Request Date", "inreqh.REQ_DATE", onenine, 2),
  // generateColumn("3", "⋮⋮⋮", false, "Notes", "inlk.[DESC]", sortalphadown, 0),      //not required for sort
  generateColumn("4", "⋮⋮⋮", false, "Amount", "inreqh.MULTI_EXPT", onenine, 2),
  generateColumn("5", "⋮⋮⋮", false, "Priority", "rq.[DESC]", sortalphadown, 0),
  generateColumn("6", "⋮⋮⋮", false, "Status", "rq.[DESC]", sortalphadown, 0),
  generateColumn("7", "⋮⋮⋮", false, "Requested By", "inreqh.ORG_AUTHID", sortalphadown, 0),
  generateColumn("8", "⋮⋮⋮", false, "Next Approver", "inreqh.NXT_AUTHID", sortalphadown, 0),
  generateColumn("9", "⋮⋮⋮", false, "Last Approver", "inreqh.LST_AUTHID", sortalphadown, 0),
  generateColumn("10", "⋮⋮⋮", false, "Instruction", "inreqh.RQH_DESC", sortalphadown, 0),
  generateColumn("11", "⋮⋮⋮", false, "Contact Person", "inreqh.CONTACT", sortalphadown, 0),
  generateColumn("12", "⋮⋮⋮", false, "Auth Group", "AUTHORIZE", sortalphadown, 0),
  // generateColumn("13", "⋮⋮⋮", false, "Auth.History", "inreqh.AUTH_HIST", sortalphadown, 0),      //not required for sort
];

export const createIssueDetailColumnsConfig = [
  generateColumn("1", "⋮⋮⋮", false, "Line No", "inreqd.LINE_NO", onenine, 2),
  generateColumn("2", "⋮⋮⋮", false, "Stock Code", "inreqd.STOCK", sortalphadown, 0),
  generateColumn("3", "⋮⋮⋮", false, "Location", "inreqd.LOCATION", sortalphadown, 0),
  generateColumn("4", "⋮⋮⋮", false, "Description", "CONVERT(inreqd.[DESC], SQL_CHAR)", sortalphadown, 0),
  generateColumn("5", "⋮⋮⋮", false, "Available Qty", "inreqd.REQ_QTY", onenine, 2),
  generateColumn("6", "⋮⋮⋮", false, "Req Qty", "inreqd.REQ_QTY", onenine, 2),
  generateColumn("7", "⋮⋮⋮", false, "Rate", "inreqd.BASE_EST", onenine, 2),
  generateColumn("8", "⋮⋮⋮", false, "Amount", "inreqd.BASE_AMT", onenine, 2),
  generateColumn("9", "⋮⋮⋮", false, "Job Code", "inreqd.JOB_CODE", sortalphadown, 0),
  generateColumn("10", "⋮⋮⋮", false, "Work Order No", "inreqd.MK_WONO", sortalphadown, 0),
  generateColumn("11", "⋮⋮⋮", false, "GL Account", "inreqd.GL_ACCOUNT", sortalphadown, 0),
];

export const closeUnprocessedColumnConfig = [
  generateColumn("1", "⋮⋮⋮", false, "Request No", "inreqh.REQ", sortalphadown, 0),
  generateColumn("2", "⋮⋮⋮", false, "Request Date", "inreqh.REQ_DATE", onenine, 2),
  // generateColumn("3", "⋮⋮⋮", false, "Notes", "inreqh.REQ_NOTES", sortalphadown, 0),    //not required for sort
  generateColumn("4", "⋮⋮⋮", false, "Amount", "inreqh.MULTI_EXPT", onenine, 2),
  generateColumn("5", "⋮⋮⋮", false, "Priority", "rq.[DESC]", sortalphadown, 0),
  generateColumn("6", "⋮⋮⋮", false, "Status", "rq.[DESC]", sortalphadown, 0),
  generateColumn("7", "⋮⋮⋮", false, "Requested By", "inreqh.ORG_AUTHID", sortalphadown, 0),
  generateColumn("8", "⋮⋮⋮", false, "Next Approver", "inreqh.NXT_AUTHID", sortalphadown, 0),
  generateColumn("9", "⋮⋮⋮", false, "Last Approver", "inreqh.LST_AUTHID", sortalphadown, 0),
  generateColumn("10", "⋮⋮⋮", false, "Instruction", "inreqh.RQH_DESC", sortalphadown, 0),
  generateColumn("11", "⋮⋮⋮", false, "Contact Person", "inreqh.CONTACT", sortalphadown, 0),
  generateColumn("12", "⋮⋮⋮", false, "Auth Group", "AUTHORIZE", sortalphadown, 0),
  // generateColumn("13", "⋮⋮⋮", false, "Auth.History", "inreqh.AUTH_HIST", sortalphadown, 0),    //not required for sort
];

export const closeUnprocessedDetailColumnConfig = [
  generateColumn("1", "⋮⋮⋮", false, "Line No", "inreqd.LINE_NO", onenine, 2),
  generateColumn("2", "⋮⋮⋮", false, "Stock Code", "inreqd.STOCK", sortalphadown, 0),
  generateColumn("3", "⋮⋮⋮", false, "Location", "inreqd.LOCATION", sortalphadown, 0),
  generateColumn("4", "⋮⋮⋮", false, "Description", "CONVERT(inreqd.[DESC], SQL_CHAR)", sortalphadown, 0),
  generateColumn("5", "⋮⋮⋮", false, "Req Qty", "inreqd.REQ_QTY", onenine, 2),
  generateColumn("6", "⋮⋮⋮", false, "Rate", "inreqd.BASE_EST", onenine, 2),
  generateColumn("7", "⋮⋮⋮", false, "Amount", "inreqd.BASE_AMT", onenine, 2),
  generateColumn("8", "⋮⋮⋮", false, "Job Code", "inreqd.JOB_CODE", sortalphadown, 0),
  generateColumn("9", "⋮⋮⋮", false, "Work Order No", "inreqd.MK_WONO", sortalphadown, 0),
  generateColumn("10", "⋮⋮⋮", false, "GL Account", "inreqd.GL_ACCOUNT", sortalphadown, 0),
];

export const approveStockColdefConfig = [
  {
    headerName: "Request No",
    field: "requestNo",
    filter: "agTextColumnFilter",
    minWidth: 170,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true,
    showDisabledCheckboxes: true,
    rowDrag: true,
  },
  {
    headerName: "Request Date",
    field: "date",
    minWidth: 140,
    filter: "agDateColumnFilter",
    comparator: (date1, date2) => {
      const parseDate = (date) => {
        const [day, month, year] = date.split('/');
        return new Date(`${year}-${month}-${day}`).getTime();
      };
      return parseDate(date1) - parseDate(date2);
    }
  },
  {
    headerName: "Notes",
    field: "notes",
    minWidth: 100,
    filter: "agTextColumnFilter",
    rowDrag: true,
  },
  {
    headerName: "Amount",
    field: "amount",
    filter: "agNumberColumnFilter",
    type:"rightAligned",
    headerClass: "ag-right-aligned-header", 
    minWidth: 120,
    comparator: (a, b) => parseFloat(a.replace(/,/g, '')) - parseFloat(b.replace(/,/g, '')),
    cellStyle: (params) => {
      return {
        textAlign: "right",
        paddingRight: "10px",
        boxSizing: "border-box",
      };
    },
  },
  {
    headerName: "Priority",
    field: "priority",
    minWidth: 100,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Status",
    field: "status",
    minWidth: 110,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Requested By",
    field: "requestedBy",
    minWidth: 130,
    filter: "agTextColumnFilter",
    rowDrag: true,
  },
  {
    headerName: "Next Approver",
    field: "authorizeBy",
    minWidth: 130,
    filter: "agTextColumnFilter",
    rowDrag: true,
  },
  {
    headerName: "Last Approver",
    field: "lastAuthorizeBy",
    minWidth: 130,
    filter: "agTextColumnFilter",
    rowDrag: true,
  },
  {
    headerName: "Instruction",
    field: "instructions",
    minWidth: 150,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Contact Person",
    field: "contactPerson",
    minWidth:150,
    filter: "agTextColumnFilter",
    rowDrag: true,
  },

  {
    headerName: "Auth Group",
    field: "authGroup",
    minWidth: 130,
    filter: "agTextColumnFilter",
    rowDrag: true,
  },
  {
    headerName: "Auth.History",
    field: "authHistory",
    minWidth: 150,
    filter: "agTextColumnFilter",
    rowDrag: true,
  },
];

export const approveStockDetailsColdefConfig = [
  {
    headerName: "Line No",
    field: "lineNo",
    valueGetter: "node.rowIndex + 1",
    filter: "agNumberColumnFilter",
    minWidth: 100,
    rowDrag: true,
  },
  {
    headerName: "Stock Code",
    field: "code",
    minWidth: 130,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Location",
    field: "location",
    minWidth: 110,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Description",
    field: "description",
    minWidth: 300,
    filter: "agDateColumnFilter",
  },
  {
    headerName: "Req.Qty",
    field: "requiredQty",
    type:"rightAligned",
    filter: "agNumberColumnFilter",
    minWidth: 120,
    valueGetter: (params) =>
    `${params?.data?.requiredQty?.toLocaleString(
      undefined,
      {
        minimumFractionDigits: 4,
        maximumFractionDigits: 4,
      }
    )}`,
    comparator: (a, b) => parseFloat(a.replace(/,/g, '')) - parseFloat(b.replace(/,/g, '')),
    cellStyle: (params) => {
      return {
        textAlign: "right",
        paddingRight: "10px",
      };
    },
  },
  {
    headerName: "Rate",
    field: "unitCost",
    filter: "agNumberColumnFilter",
    type:"rightAligned",
    minWidth: 100,
    valueGetter: (params) =>
    `${params?.data?.unitCost?.toLocaleString(
      undefined,
      {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }
    )}`,
    comparator: (a, b) => parseFloat(a.replace(/,/g, '')) - parseFloat(b.replace(/,/g, '')),
    cellStyle: (params) => {
      return {
        textAlign: "right",
        paddingRight: "10px",
      };
    },
  },
  {
    headerName: "Amount",
    field: "amount",
    filter: "agNumberColumnFilter",
    minWidth: 120,
    type:"rightAligned",
    valueGetter: (params) =>
      `${params?.data?.amount?.toLocaleString(
        undefined,
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      )}`,
    comparator: (a, b) => parseFloat(a.replace(/,/g, '')) - parseFloat(b.replace(/,/g, '')),
    cellStyle: (params) => {
      return {
        textAlign: "right",
        paddingRight: "10px",
      };
    },
  },
  

  {
    headerName: "Job Code",
    field: "jobCode",
    filter: "agTextColumnFilter",
    minWidth: 160,
    valueGetter: (params) =>
      `${params.data.jobCode}.${params.data.phaseCode}.${params.data.reqCode}`,
  },
  {
    headerName: "Work Order No",
    field: "workOrderNo",
    filter: "agTextColumnFilter",
  },
  {
    headerName: "GL Account",
    field: "glAccount",
    filter: "agTextColumnFilter",
  },
];

export const closeUnprocesStockColdefConfig = [
  {
    headerName: "Request No",
    field: "requestNo",
    filter: "agTextColumnFilter",
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true,
    minWidth: 170,
    rowDrag: true,
    cellRenderer: "iconComponent",
    showDisabledCheckboxes: true,
  },
  {
    headerName: "Request Date",
    field: "date",
    minWidth: 140,
    filter: "agDateColumnFilter",
    comparator: (date1, date2) => {
      const parseDate = (date) => {
        const [day, month, year] = date.split('/');
        return new Date(`${year}-${month}-${day}`).getTime();
      };
      return parseDate(date1) - parseDate(date2);
    }
  },
  {
    headerName: "Notes",
    field: "notes",
    minWidth: 100,
    filter: "agTextColumnFilter",
    rowDrag: true,
  },
  {
    headerName: "Amount",
    field: "amount",
    filter: "agNumberColumnFilter",
    type:"rightAligned",
    minWidth: 120,
    comparator: (a, b) => parseFloat(a.replace(/,/g, '')) - parseFloat(b.replace(/,/g, '')),
    cellStyle: (params) => {
      return {
        textAlign: "right",
        paddingRight: "10px",
      };
    },
  },
  {
    headerName: "Priority",
    field: "priority",
    minWidth: 100,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Status",
    field: "status",
    minWidth: 160,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Requested By",
    field: "requestedBy",
    minWidth: 130,
    filter: "agTextColumnFilter",
    rowDrag: true,
  },
  {
    headerName: "Next Approver",
    field: "authorizeBy",
    minWidth: 130,
    filter: "agTextColumnFilter",
    rowDrag: true,
  },
  {
    headerName: "Last Approver",
    field: "lastAuthorizeBy",
    minWidth: 130,
    filter: "agTextColumnFilter",
    rowDrag: true,
  },
  {
    headerName: "Instruction",
    field: "instructions",
    minWidth: 150,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Contact Person",
    field: "contactPerson",
    minWidth:150,
    filter: "agTextColumnFilter",
    rowDrag: true,
  },
  
 
  {
    headerName: "Auth Group",
    field: "authGroup",
    minWidth: 130,
    filter: "agTextColumnFilter",
    rowDrag: true,
  },
  {
    headerName: "Auth.History",
    field: "authHistory",
    minWidth: 700,
    filter: "agTextColumnFilter",
    tooltipValueGetter: (params: ITooltipParams) => params.value,
    rowDrag: true,
  },
];

export const closeUnprocesStockDetailsColdefConfig = [
  {
    headerName: "Line No",
    field: "lineNo",
    minWidth: 100,
    filter: "agNumberColumnFilter",
    rowDrag: true,
  },
  {
    headerName: "Stock Code",
    field: "code",
    minWidth: 130,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Location",
    field: "location",
    minWidth: 130,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Description",
    field: "description",
    minWidth: 250,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Req Qty",
    field: "requiredQty",
    filter: "agNumberColumnFilter",
    type:"rightAligned",
    minWidth: 120,
    valueGetter: (params) =>
    `${params?.data?.requiredQty?.toLocaleString(
      undefined,
      {
        minimumFractionDigits: 4,
        maximumFractionDigits: 4,
      }
    )}`,
    comparator: (a, b) => parseFloat(a.replace(/,/g, '')) - parseFloat(b.replace(/,/g, '')),
    cellStyle: (params) => {
      return {
        textAlign: "right",
        paddingRight: "10px",
      };
    },
  },
  {
    headerName: "Rate",
    field: "unitCost",
    filter: "agNumberColumnFilter",
    type:"rightAligned",
    minWidth: 100,
    valueGetter: (params) =>
    `${params?.data?.unitCost?.toLocaleString(
      undefined,
      {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }
    )}`,
    comparator: (a, b) => parseFloat(a.replace(/,/g, '')) - parseFloat(b.replace(/,/g, '')),
    cellStyle: (params) => {
      return {
        textAlign: "right",
        paddingRight: "10px",
      };
    },
  },
  {
    headerName: "Amount",
    field: "amount",
    filter: "agNumberColumnFilter",
    type:"rightAligned",
    minWidth: 120,
    valueGetter: (params) =>
      `${params?.data?.amount?.toLocaleString(
        undefined,
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      )}`,
    comparator: (a, b) => parseFloat(a.replace(/,/g, '')) - parseFloat(b.replace(/,/g, '')),
    cellStyle: (params) => {
      return {
        textAlign: "right",
        paddingRight: "10px",
      };
    },
  },
  {
    headerName: "Job Code",
    field: "jobCode",
    filter: "agTextColumnFilter",
    minWidth: 160,
    valueGetter: (params) =>
      `${params.data.jobCode}.${params.data.phaseCode}.${params.data.reqCode}`,
  },
  {
    headerName: "Work Order No",
    field: "workOrderNo",
    filter: "agTextColumnFilter",
  },
  {
    headerName: "GL Account",
    field: "glAccount",
    filter: "agTextColumnFilter",
  },
  
];
export const createIssueBatchColdefConfig = [
  {
    headerName: "Request No",
    field: "requestNo",
    filter: "agTextColumnFilter",
    headerCheckboxSelectionFilteredOnly: true,
    minWidth: 170,
    checkboxSelection: true,
    rowDrag: true,
    cellRenderer: "iconComponent",
    showDisabledCheckboxes: true,
  },
  {
    headerName: "Request Date",
    field: "date",
    minWidth: 140,
    filter: "agDateColumnFilter",
    comparator: (date1, date2) => {
      const parseDate = (date) => {
        const [day, month, year] = date.split('/');
        return new Date(`${year}-${month}-${day}`).getTime();
      };
      return parseDate(date1) - parseDate(date2);
    }
  },
  {
    headerName: "Notes",
    field: "notes",
    minWidth:100,
    filter: "agTextColumnFilter",
    rowDrag: true,
  },
  {
    headerName: "Amount",
    field: "amount",
    filter: "agNumberColumnFilter",
    type: 'rightAligned' ,
    valueGetter: (params) => {
      const formattedAmount = Number(params.data.totalAmount)?.toLocaleString(
        undefined,
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      );
      return `${formattedAmount}`;
    },
    minWidth: 120,
    comparator: (a, b) => parseFloat(a.replace(/,/g, '')) - parseFloat(b.replace(/,/g, '')),
    cellStyle: (params) => {
      return {
        textAlign: "right",
        paddingRight: "10px",
      };
    },
  },
  {
    headerName: "Priority",
    field: "priority",
    minWidth: 100,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Status",
    field: "status",
    minWidth: 140,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Requested By",
    field: "requestedBy",
    minWidth: 130,
    filter: "agTextColumnFilter",
    rowDrag: true,
  },
  {
    headerName: "Next Approver",
    field: "authorizeBy",
    minWidth: 130,
    filter: "agTextColumnFilter",
    rowDrag: true,
  },
  {
    headerName: "Last Approver",
    field: "lastAuthorizeBy",
    minWidth: 130,
    filter: "agTextColumnFilter",
    rowDrag: true,
  },
  {
    headerName: "Instruction",
    field: "instructions",
    minWidth: 150,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Contact Person",
    field: "contactPerson",
    minWidth:150,
    filter: "agTextColumnFilter",
    rowDrag: true,
  },
  {
    headerName: "Auth Group",
    field: "authGroup",
    minWidth: 130,
    filter: "agTextColumnFilter",
    rowDrag: true,
  },
  {
    headerName: "Auth.History",
    field: "authHistory",
    minWidth: 700,
    filter: "agTextColumnFilter",
    headerTooltip: "Country Column",
    tooltipValueGetter: (params: ITooltipParams) => params.value,
    rowDrag: true,
  },
];

export const createIssueBatchDetailsColdefConfig = [

  {
    headerName: "Stock Code",
    field: "code",
    minWidth: 130,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Location",
    field: "location",
    minWidth: 120,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Description",
    field: "description",
    minWidth: 250,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Available Qty",
    field: "availableQty",
    minWidth: 140,
    filter: "agTextColumnFilter",
    type: "rightAligned",
    valueGetter: (params) =>
      `${params?.data?.availableQty?.toLocaleString(
        undefined,
        {
          minimumFractionDigits: 4,
          maximumFractionDigits: 4,
        }
      )}`,
    comparator: (a, b) => parseFloat(a.replace(/,/g, '')) - parseFloat(b.replace(/,/g, '')),

    cellStyle: (params) => {
      let color = ''; 
      if (params?.data?.status !== "I1" && params?.data?.status !== "IX" && params?.data?.availableQty <= (params?.data?.requiredQty - params?.data?.inQty)) {
        color = '#FFC0C0';
      }
      return {
        textAlign: "right",
        paddingRight: "10px",
        backgroundColor: color,
      };
    }
  },
  {
    headerName: "Req Qty",
    field: "requiredQty",
    filter: "agNumberColumnFilter",
    minWidth: 120,
    type:"rightAligned",
    cellStyle: (params) => {
      return {
        textAlign: "right",
        paddingRight: "10px",
      };
    },
    valueGetter: (params) =>
      `${params?.data?.requiredQty?.toLocaleString(
        undefined,
        {
          minimumFractionDigits: 4,
          maximumFractionDigits: 4,
        }
      )}`,
    comparator: (a, b) => parseFloat(a.replace(/,/g, '')) - parseFloat(b.replace(/,/g, '')),
  },
  {
    headerName: "Rate",
    field: "unitCost",
    filter: "agNumberColumnFilter",
    type:"rightAligned",
    minWidth: 110,
    valueGetter: (params) =>
      `${params?.data?.unitCost?.toLocaleString(
        undefined,
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      )}`,
    comparator: (a, b) => parseFloat(a.replace(/,/g, '')) - parseFloat(b.replace(/,/g, '')),
    cellStyle: (params) => {
      return {
        textAlign: "right",
        paddingRight: "10px",
      };
    },
  },
  {
    headerName: "Amount",
    field: "amount",
    filter: "agNumberColumnFilter",
    type:"rightAligned",
    valueGetter: (params) =>
      `${params?.data?.amount?.toLocaleString(
        undefined,
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      )}`,
    minWidth: 120,
    comparator: (a, b) => parseFloat(a.replace(/,/g, '')) - parseFloat(b.replace(/,/g, '')),
    cellStyle: (params) => {
      return {
        textAlign: "right",
        paddingRight: "10px",
      };
    },
  },
  {
    headerName: "Job Code",
    field: "jobNumber",
    minWidth: 160,
    filter: "agTextColumnFilter",
    valueGetter: (params) =>
      `${params.data.jobCode}.${params.data.phaseCode}.${params.data.reqCode}`,
  },
  {
    headerName: "Work Order No",
    field: "workOrderNo",
    filter: "agTextColumnFilter",
  },
  {
    headerName: "GL Account",
    field: "glAccount",
    filter: "agTextColumnFilter",
  },
  
 
];
