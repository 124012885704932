import { useState, useRef } from "react";
import Tippy from "@tippyjs/react";
import "./ActionPopup.css";
import { KTSVG } from "../../../theme/helpers";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../modules/auth";
import { updateLookupData, updateStockCode } from "../../../reducers/lookupReducer";
import { useDispatch, useSelector } from "react-redux";
import { generatePickupSlip } from "../../modules/apps/user-management/users-list/core/_requests";
import { updateIsCreateRequest, updateIsValidRouteForRequestStock } from "../../../reducers/indexReducer";
const ActionPopupDev = (props) => {
  const dispatch = useDispatch();
  const status = props?.data?.status;
  const currentDate = new Date(Date.now());
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const day = currentDate.getDate().toString().padStart(2, "0");
  const formattedDate = `${day}-${month}-${year}`;
  const hours = currentDate.getHours().toString().padStart(2, "0");
  const minutes = currentDate.getMinutes().toString().padStart(2, "0");
  const seconds = currentDate.getSeconds().toString().padStart(2, "0");
  const formattedTime = `${hours}:${minutes}:${seconds}`;
  const {
    setEditable,
    setDeleteid,
    setPageStatus,
    setGroup,
    setSelectedRowIndex,
  } = useAuth();
  const company = useSelector((state: any) => state?.userConfig?.company);
  const tippyRef: any = useRef();
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);
  const navigate = useNavigate();
  const currentUrl = window.location.href;
  const includesStock = currentUrl?.includes("/stock");
  const requestNoValue = props?.data?.requestNo;

  // Store the value in local storage
  localStorage.setItem("deleteRequestNo", requestNoValue);
  const dropDownContent = includesStock ? (
    <div className="menu-container shadow-sm">
      <div
        onClick={() => onClickstock("view")}
        className="menu-items text-hover-primary"
      >
        View
      </div>
      {status == "Not Submitted yet" && (
        <div
          onClick={() => onClickstock("edit")}
          className="menu-items text-hover-primary"
        >
          Edit
        </div>
      )}
      {(status == "Not Submitted yet" ||
        status == "In Process" ) && (
        <div
          onClick={() => onClickstock("delete")}
          className="menu-items text-hover-primary"
        >
          Delete
        </div>
      )}
      {status == "Fully Authorized" && (
        <div
          onClick={() => onClickstock("picking-slip")}
          className="menu-items text-hover-primary"
        >
          View Picking Slip
        </div>
      )}
    </div>
  ) : (
    <div className="menu-container shadow-sm">
      <div
        onClick={() => onClickHandler("view")}
        className="menu-items text-hover-primary"
      >
        View
      </div>
      <div
        onClick={() => onClickHandler("edit-access")}
        className="menu-items text-hover-primary"
      >
        Edit Access
      </div>
      <div
        onClick={() => onClickHandler("edit")}
        className="menu-items text-hover-primary"
      >
        Edit Details
      </div>
    </div>
  );
  const onClickstock = (option) => {
    hide();
    switch (option) {
      case "view":
        setSelectedRowIndex(0);
        setGroup(null);
        setEditable(false);
        setPageStatus("View");
        dispatch(updateStockCode(""));
        dispatch(updateIsCreateRequest(false)); 
        dispatch(updateIsValidRouteForRequestStock(true));
        navigate("/requeststockdev", {
          state: { ...props.data, isViewOnly: true },
        });
        break;
      case "edit":
        setSelectedRowIndex(0);
        setGroup(null);
        setEditable(true);
        dispatch(updateStockCode(""));                   //It will avoid an addition api call for the stock code that was last added.
        dispatch(updateIsCreateRequest(false));
        dispatch(updateIsValidRouteForRequestStock(true));
        navigate("/requeststockdev", { state: props.data });
        setPageStatus("Edit");
        break;
      case "delete":
        setDeleteid(true);

        break;
      case "picking-slip":
        generatePickupSlip(
          {
            companyCode: company,
            generateDate: formattedDate,
            generateTime: formattedTime,
            reqNo: props.data.requestNo,
            status: props.data.status,
          },
          props?.data?.lineItems || []
        );

        break;

      default:
        break;
    }
  };

  const onClickHandler = (option) => {
    hide();
    let state = { data: props.data, isEditAccess: false };

    switch (option) {
      case "view":
        setEditable(false);
        navigate("/detailsdev", { state });
        break;
      case "edit-access":
        state.isEditAccess = true;
        setEditable(true);
        navigate("/detailsdev", { state });
        break;
      case "edit":
        setEditable(true);
        navigate("/createuser", { state: props?.data?.userId });
        break;
      case "copy":
        break;
      default:
        break;
    }
  };

  const onClick = (event) => {
    event.preventDefault();
    if (visible) {
      hide();
    } else {
      show();
    }
  };

  return (
    <Tippy
      ref={tippyRef}
      content={dropDownContent}
      visible={visible}
      onClickOutside={hide}
      allowHTML={true}
      arrow={false}
      appendTo={document.body}
      interactive={true}
      placement="right"
    >
      <a
        href="#!"
        className="btn btn-light btn-active-light-primary btn-sm action"
        onClick={onClick}
      >
        Actions
        <KTSVG
          path="/media/icons/duotune/arrows/arr072.svg"
          className="svg-icon-5 m-0"
        />
      </a>
    </Tippy>
  );
};

export default ActionPopupDev;
