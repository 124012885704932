import { useEffect, useState } from "react";
import tax from "../../../assets/demo/tax management.png";
import pin from "../../../assets/pin.png";
import unpin from "../../../assets/unpin.png";
import { colors, titleCss } from "../../../constants/colors";
import clsx from "clsx";
import "./DashboardCard.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../modules/auth";
import {
  getNotification,
  saveOrUpdateNotifications,
  savePinsFav
} from "../../modules/apps/user-management/users-list/core/_requests";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { updateLookupData } from "../../../reducers/lookupReducer";
import { URLS } from "../../utils/URLS";
import { useTranslation } from "react-i18next";
import { updateMenuItem } from "../../../reducers/menuReducer";
import { toastMessage } from "../../modules/auth/functions/toastMessage";
import { Spinner } from "react-bootstrap";

const DashboardCard = ({ data, onChange }) => {
  const {t} = useTranslation();
  useEffect(() => {
    localStorage.setItem("createIssueBatch", JSON.stringify([]));
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      updateLookupData([
        {
          id: 1,
          name: "user",
        },
      ])
    );
  }, []);

  const navigate = useNavigate();
  const {
    currentUser,
    setIsAuthNeeded,
    setEditable,
  } = useAuth();
  let pinnedData: any = localStorage.getItem("pinnedData");
  pinnedData = JSON.parse(pinnedData);
  const extractValueBetweenHash = () => {
    const regex = /#(.*?)#/;
    const match = currentUser?.sub?.match(regex);
    if (match) {
      return match[1];
    }
    return null;
  };
  const currentUserName = extractValueBetweenHash() || "";
  const [isPinLoading,setIsPinLoading]=useState(false);

  const handleClick = async (e, data) => {
    e.stopPropagation();
    setIsPinLoading(true);
    try {
      const response= await savePinsFav({
        companyCode: company,
        userId: currentUser!.sub.split("#")[1],
        applicationCode: data.applicationCode,
        mniName: data.mniName,
        pinOrFav: "Pin",
        pinOrFavStatus: `${!data.pinned}`,
      });
      if(response){
        dispatch(updateMenuItem({mniName:data.mniName,type:"Pin"}));
      }
      else{
        toastMessage(t("Could not pin the card Please try again later."),"error");
      }
      
    } catch (error) {
      toastMessage(t("Failed to Pin the Card Please contact support."),"error");
    } finally{
      setIsPinLoading(false);
    }
    
  };

  const company = useSelector((state: any) => state?.userConfig?.company);
  const handlecardclick = async (data) => {
    navigate(`/dashboard`, { state: { filter: `${data.menu_item}` } });
  };

  const handleClickFn = (to, data) => {
    if (data.menu_item === "Add New User") {
      setEditable(false);
    }
    if (data?.application==="Requisition") {
      window.open(`${URLS.REACT_APP_REQUISITION_URL}${data.webUrl}`, "_blank");
      return;
    }
    data?.mniName === "MNI_StockReq_Authorisation" && setIsAuthNeeded(true);
  
    navigate(`/${to}`);

    if (
      data?.mniName === "MNI_StockReq_Maintenance" ||
      data?.mniName === "MNI_StockReq_Authorisation"
    ) {
      const fetchSaveOrUpdateNotifications = async () => {
        try {
           await saveOrUpdateNotifications({
            isEditCase: true,
            assignTo: currentUserName,
            companyCode: company,
            mniName: data.mniName,
            requestedBy: "",
          });
        } catch (error) {}
      };
      fetchSaveOrUpdateNotifications();
    }
  };

  const isAvailable =
    data?.menu_item == "User Maintenance" ||
    data?.menu_item == "User Listing " ||
    data?.menu_item == "Add New User" ||
    data?.menu_item == "Create Stock Request" ||
    data?.menu_item == "Approve Stock Request" ||
    data?.menu_item == "Stock Request" ||
    data?.menu_item == "Close Unprocessed Stock Request" ||
    data?.menu_item == "PO Enquiry" ||
    data?.menu_item == "Stock Enquiry" ||
    data?.menu_item == "Convert Stock Request into Issue batch" ||
    data?.menu_item == "Create Purchase Request" ||
    data?.menu_item == "Approve Purchase Request" ||
    data?.menu_item == "Purchase Request" ||
    data?.menu_item == "Approved/Rejected Request Listing";

  const {
    refetch,
    data: response,
  } = useQuery([`stock-request-list`], () => {
    return getNotification(company, "", "MNI_StockReq_Maintenance");
  });

  const {
    refetch: refetchone,
    data: response2,
  } = useQuery([`stock-request`], () => {
    return getNotification(company, "", "MNI_StockReq_Authorisation");
  });

  useEffect(() => {
    refetchone();
  }, [data?.path]);

  useEffect(() => {
    refetch();
  }, [data?.path]);

  const calculateTotal = (...values) => {
    const total = values.reduce((acc, value) => acc + (value || 0), 0);
    return isNaN(total) || total === 0 ? 0 : total;
  };

  return (
        <div
          className={`card  d-flex gap-5 pt-10 pb-5 border border-2 ${
            isAvailable ? "border-primary" : "border-secondary"
          }  container-card cursor-pointer h-200px`}
          style={{ backgroundColor: isAvailable ? "white" : "#e6e6e6" }}
          onClick={() => {
            data?.path
              ? handleClickFn(data?.path, data)
              : handlecardclick(data);
          }}
        >
          {calculateTotal(response?.totalCounter, response2?.totalCounter) >
            0 &&
            (data?.mniName == "MNI_StockRequest" ||
              data?.mniName == response?.data[0]?.mniName ||
              data?.mniName == response2?.data[0]?.mniName) && (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: colors.badge,
                  borderRadius: "50%",
                  padding: 5,
                  height: "35px",
                  width: "35px",
                  position: "absolute",
                  right: -13,
                  top: -13,
                  cursor: "auto",
                }}
                onClick={(e) => {}}
              >
                {data?.mniName == "MNI_StockRequest" ? (
                  <span className="text-white fs-5 fw-bolder">
                    {calculateTotal(
                      response?.totalCounter,
                      response2?.totalCounter
                    )}
                  </span>
                ) : data?.mniName == "MNI_StockReq_Maintenance" ? (
                  <span className="text-white fs-5 fw-bolder">
                    {calculateTotal(response?.totalCounter)}
                  </span>
                ) : (
                  <span className="text-white fs-5 fw-bolder">
                    {calculateTotal(response2?.totalCounter)}
                  </span>
                )}
              </div>
            )}
          <div className="d-flex align-items-center gap-5 px-10">
            <img
              src={data.icon || tax}
              style={{ height: "35px", width: "35px" }}
            />
            <div className={clsx(titleCss)} style={{ color: colors.title }}>
              {t(data.menu_item)}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "start",
              height: "100vh",
            }}
          >
            <div
              className="fs-7 px-10 mt-4 d-flex align-items-start justify-content-start"
              style={{
                width: "auto", // Set width to auto
                marginBottom: "50px", // Adjust this value to move the div up or down
              }}
            >
              {t(data.textDisplay)}
            </div>
          </div>

          <div className="d-flex justify-content-center align-items-center ">
            <div className=""></div>
            <div
              className="cursor-pointer"
              style={{ position: "absolute", right: 20, bottom: 20 }}
              onClick={(e) => handleClick(e, data)}
            >
              {isPinLoading ? (
                <Spinner animation="border" variant="success" />
              ) : (
                data?.type === "function" &&
                (!data.pinned ? (
                  <img src={pin} style={{ height: "20px", width: "20px" }} />
                ) : (
                  <img src={unpin} style={{ height: "20px", width: "20px" }} />
                ))
              )}
            </div>
          </div>
        </div>
  );
};

export default DashboardCard;
