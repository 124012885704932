import React,{ useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import {
  getModifyTable,
  getResetColumn,
  getRestoreCustomizeLayout,
} from "../../modules/apps/user-management/users-list/core/_requests";
import { toastMessage } from "../../modules/auth/functions/toastMessage";
import { HandleApiCall } from "../../modules/auth/functions/HandleApiCall";
import { useSelector } from "react-redux";
import CustomLoadingCellRender from "../user/CustomLoadingCellRender";
import "ag-grid-charts-enterprise";
import Tippy from "@tippyjs/react";
import { functionsData } from "./functionDataDev";
import { KTSVG } from "../../../theme/helpers";

import ValueFormatter from "../../utils/ValueFormatter"; 
import HyperlinkRenderer from "../../utils/HyperlinkRendererProps";

import { useTranslation } from "react-i18next";
import SearchInputDev from "../../utils/SearchInputDev";
import DevExport from "../../modules/custom components/Export/DevExport";
import { UserEditModalDev } from "../../modules/apps/user-management/users-list/user-edit-modal/UserEditModalDev";
import DataGridWrapper from "../../utils/DataGrid/DataGridWrapper";


interface ActionData{
   id: number; 
   label: string;
}

const DevDynamicGridTable = ({
  type,
  tabTitle,
  tabSqlData,
  tabStorageData,
  tabStorage,
  tabSqlName,
  tableConfiguration,
  tabId,
  fetchOthersTabData,
  customizeGridEnabled
}) => {
  const [newTabStorageData,setNewTabStorageData]=useState(tabStorageData);
  const {t} = useTranslation();
  
  // const isCustomizedColumn = newTabStorageData?.filter((item) => item?.columnType == "Numeric");
  const company = useSelector((state: any) => state?.userConfig?.company);

  const [columnDefs, setColumnDefs] = useState<any>([]);
  const [gridApi, setGridApi] = useState<any>(null);
  const [showModal, setShowModal] = useState<any>("");
  const [showCustomizeModal, setShowCustomizeModal] = useState<any>(false);
  
  const [multiLineSelection, setMultiLineSelection] = useState<any>(tableConfiguration?.multiLineSelection ? "multiple" : "single");
  const [showFiltering, setShowFiltering] = useState(tableConfiguration?.filterVisible);
  const [hideAndShowSearchPanel, setHideAndShowSearchPanel] = useState<any>(tableConfiguration?.searchPanelVisible);
  const [showGroupingPanel, setShowGroupingPanel] = useState<any>(tableConfiguration?.groupingPanelVisible ? "always" : "never");
  const[summaryFooterVisible,setSummaryFooterVisible]=useState(tableConfiguration?.summaryFooterVisible);
  const [groupFooterVisible,setGroupFooterVisible]=useState(tableConfiguration?.groupFooterVisible);
  const [isGroupingExpandable,setIsGroupingExpandable]=useState(tableConfiguration?.isGroupingExpandable || false);


  const [isLoadingData, setIsLoadingData] = useState(true);
  const gridRef: any = useRef(null);


  const tippyRef: any = useRef();
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);
  const actionPopUpDataRef = useRef<ActionData[]>();
  const fileName = `${type}-item`;

  // console.log("tabId in Dynamic",tabId);

  const updatedActionPopupData = functionsData.map((item) => {
    switch (item.label) {
      case "Show Grouping Panel":
        return {
          ...item,
          label: showGroupingPanel === "always"
            ? "Hide Grouping Panel"
            : "Show Grouping Panel",
        };
      case "Show Group Footer":
        return {
          ...item,
          label: groupFooterVisible
            ? "Hide Group Footer"
            : "Show Group Footer",
        };
      case "Show All":
          return {
            ...item,
            label: isGroupingExpandable
              ? "Collapse All"   
              : "Show All",
          };
      case "Show Search Panel":
        return {
          ...item,
          label: hideAndShowSearchPanel
            ? "Hide Search Panel"
            : "Show Search Panel",
        };
      case "Show Summary Footer":
        return {
          ...item,
          label: summaryFooterVisible
            ? "Hide Summary Footer"
            : "Show Summary Footer",
        };
      case "Show Filtering":
        return {
          ...item,
          label: showFiltering
            ? "Hide Filtering"
            : "Show Filtering",
        };
      case "Enable Multi-Line Selection":
        return {
          ...item,
          label: multiLineSelection
            ? "Disable Multi-Line Selection"
            : "Enable Multi-Line Selection",
        };

      default:
        return item;
    }
  });
  actionPopUpDataRef.current = updatedActionPopupData;
  
  const dropDownContent = (
    <div className="menu-container shadow-sm">
      {actionPopUpDataRef?.current?.map((item, index) => (
        <div
          key={index}
          onClick={() => {
            hide();
            onEditColumnButtonClick(item.id);
          }}
          className="menu-items text-hover-primary"
        >
          {t(item.label)}
        </div>
      ))}
    </div>
  );

  const onTippyClick = (event) => {
    event.preventDefault();
    if (visible) {
      hide();
    } else {
      show();
    }
  };
  const compareByColumnIndex = (a, b) => {
    return a.columnIndex - b.columnIndex;
  }

  useEffect(() => {
    setIsLoadingData(true);
    setTimeout(() => {
      setIsLoadingData(false);
    }, 100);
  }, [tabTitle, tabSqlData]);

  useEffect(() => {
    if (newTabStorageData) {
      newTabStorageData.sort(compareByColumnIndex);
      let columnType = newTabStorageData.filter((item) => item.infoType === "column");
      let cols = columnType.map((item) => {
        let valueFormatter;
          if (
            item?.columnType==='Numeric'
          ) {
              valueFormatter = (params) => { return (params.value || (params.value == 0)) ? ValueFormatter(params?.value,item?.numericFormat,item?.columnName) : "" };

          }
        return {
          headerName: item?.caption,
          field: item?.columnName,
          filter: "agTextColumnFilter",
          cellRenderer: item?.columnName.toLowerCase()==="path"?HyperlinkRenderer:null,
          visible: item?.columnVisible,
          rowGroupIndex: item?.rowGroupIndex,
          valueFormatter:valueFormatter,
          halign:item?.alignment,
          width: item?.width,
          columnType: item?.columnType,
          numericFormat: item?.numericFormat,
          sortOrder: item?.sort,
          summaryType: (item?.aggFunc),
          fixed:item?.pinned,
          fixedPosition:item?.pinnedPosition
        };
      });
        // below is workaround for the case when no aggegrate function is applied for group and total summary and still we need to show the grouping bar
        cols.push({
          headerName:"Summary",
          field:"show_summary",
          visible:false
        })
      setColumnDefs(cols);
    }
  }, [newTabStorageData]);

  // on reset to default and customized, we need the new column definition to render
  useLayoutEffect(()=>{
    setNewTabStorageData(tabStorageData);
  },[tabStorageData]);
  useEffect(() => {
    // console.debug("Table configuration changed");
    if (tableConfiguration) {
      setMultiLineSelection( tableConfiguration?.multiLineSelection ? "multiple" : "single");
      setShowFiltering(tableConfiguration?.filterVisible);
      setHideAndShowSearchPanel(tableConfiguration?.searchPanelVisible);
      setShowGroupingPanel(tableConfiguration?.groupingPanelVisible ? "always" : "never");
      setSummaryFooterVisible(tableConfiguration?.summaryFooterVisible);
      setGroupFooterVisible(tableConfiguration?.groupFooterVisible);
      setIsGroupingExpandable(tableConfiguration?.isGroupingExpandable || false);
    }
  }, [tableConfiguration, tabTitle]);

  const onGridReady=useCallback((params)=>{
    console.debug("onGridReady",params.component);
    const dataGridInstance = params.component;
    setGridApi(dataGridInstance);
   
    },[newTabStorageData]);


  const getUpdatedColumnsWithIndex = () => {
    let columnState =  gridApi.getController('columns').getColumns();
      const updatedData = newTabStorageData.map((data) => {
        const matchingColumn = columnState.find(column => column.dataField === data.colId);
        if (matchingColumn) {
          return {
            ...data,
            width: matchingColumn.width,      
            columnIndex: (matchingColumn.visibleIndex+1),
            columnVisible:matchingColumn.visible,
            pinned:matchingColumn.fixed,
            pinnedPosition:matchingColumn.fixedPosition,
            rowGroupIndex:matchingColumn.groupIndex,
            sort:matchingColumn.sortOrder,
            sortIndex:matchingColumn.sortIndex
          };
        }
        return { ...data }; // Create a new object with a new reference
      });
     console.debug("updatedData",updatedData);
    setNewTabStorageData([...updatedData]); // Create a new array with a new reference
    return updatedData;
  }

  const handleCustomizeLayout = async () => {
    // console.debug("handleCustomizeLayout : ", searchVisible.current,filterVisible.current);
    let updatedTableConfiguration = {
      ...tableConfiguration,
      searchPanelVisible: hideAndShowSearchPanel,
      filterVisible: showFiltering,
      groupingPanelVisible: showGroupingPanel == "never" ? false : true,
      multiLineSelection: multiLineSelection == "single" ? false : true,
      summaryFooterVisible:summaryFooterVisible,
      groupFooterVisible: groupFooterVisible,
      isGroupingExpandable: isGroupingExpandable,
    };


    const payload = {
      tabStorage: tabStorage,
      tabSqlName: tabSqlName,
      modifiedTableConfiguration: updatedTableConfiguration,
      modifiedColumnConfiguration: getUpdatedColumnsWithIndex(),
      companyCode: company,
    };
    console.debug("customizedColumnData while saving", payload);

    try {
      setIsLoadingData(true);
      console.debug("payload",payload);
      const response = await HandleApiCall(getModifyTable, payload);
      console.debug("Response : ", response);
      if (response.status === "OK") {
        console.debug("Response : OK Response so saving state");
        toastMessage(t("Saved Customized Layout Successfully"), "success");
      } else {
        console.debug("Response : NOT OK Response");
        toastMessage(t("Error Saving Customized Layout"), "error");
      }
    } catch (error) {}
    finally{
      setTimeout(()=>{
        setIsLoadingData(false);
      },0);
    }
  };

  const onRestoreDefaultLayout = async () => {
    setIsLoadingData(true);
    try {
      const payload = {
        payload: {},
        storageTab: tabStorage,
        tabSqlName: tabSqlName,
        companyCode: company,
      };
      const response = await HandleApiCall(getResetColumn, payload);
      await fetchOthersTabData(tabId,true);
    } catch (error) {}
     finally{
      setTimeout(()=>{
        setIsLoadingData(false);
      },0);
     }
  };

  const onRestoreCustomizeLayout = async () => {
    setIsLoadingData(true);
    try {
      const payload = {
        payload: {},
        storageTab: tabStorage,
        tabSqlName: tabSqlName,
        companyCode: company,
      };
      const response = await HandleApiCall(getRestoreCustomizeLayout, payload);

      await fetchOthersTabData(tabId,true);
    } catch (error) { }
    finally{
      setTimeout(()=>{
        setIsLoadingData(false);
      },0);
    }
  };

  const closeEditModal = () => {
    setShowModal(false);
  };

  const closeEditCustomizeModal = () => {
    setShowCustomizeModal(false);
  };

  const handleEdit = async (data) => {
    console.debug("modifiedColumnNames",data.modifiedColumnNames);
    setIsLoadingData(true);
    try {
      setNewTabStorageData(data.modifiedColumnNames);
      setShowModal(false);
    } catch (error) {} 
    finally{
      setTimeout(()=>{
        setIsLoadingData(false);
      },0);
     }
  };

  const handleEditCustomizeColumn = async (data) => {
    setIsLoadingData(true);
    try {
      const modifiedFields = data.modifiedColumnNames.map((col) => col.columnName);

      const newTabStorageDataUpdated = [
        ...newTabStorageData.filter(
          (item) => !modifiedFields.includes(item.columnName)
        ),
        ...data.modifiedColumnNames,
      ];
      newTabStorageDataUpdated.sort(compareByColumnIndex)
      setNewTabStorageData(newTabStorageDataUpdated);
      setShowCustomizeModal(false);
    } catch (error) {}
     finally{
      setTimeout(()=>{
        setIsLoadingData(false);
      },0);
     }
  };

  const paginationPageSizeSelector = useMemo<number[] | boolean>(() => {
    return [10, 50, 100, 150, 200];
  }, []);


  function renderModal(
    showModal,
    modalContent,
    modalText,
    onCloseModal,
    columnHeaders,
    tabStorage,
    tabSqlName,
    onApply,
    applySummaryFooter
  ) {
    if (showModal) {
      return (
        <UserEditModalDev
          content={modalContent}
          modalText={modalText}
          onCloseEditModal={onCloseModal}
          columnHeaders={(typeof columnHeaders==='function')?columnHeaders():columnHeaders}
          tabStorage={tabStorage}
          tabSqlName={tabSqlName}
          onApply={onApply}
          applySummaryFooter={applySummaryFooter}
          style={{
            position: "relative",
            zIndex: 1000,
          }}
        />
      );
    } else {
      return null;
    }
  }

  const getCurrentColumnData = () => {
    let tobeColumnState = JSON.parse(JSON.stringify(newTabStorageData));
    const allColumns = gridApi?.getController('columns').getColumns();
    // console.log("allCOlumn",allColumns);
    const currentColumnState = allColumns?.map((column) => ({
      colId: column.dataField,
      columnIndex: (column.visibleIndex+1),
      columnVisible:column.visible,
      width: column.width,
      rowGroupIndex:column.groupIndex,
      pinned:column.fixed,
      pinnedPosition:column.fixedPosition, 
      sort:column.sortOrder,
      sortIndex:column.sortIndex

    }));
    
    if (currentColumnState) {
      tobeColumnState = tobeColumnState.map((columnObj) => {
        const matchingColumn = currentColumnState.find(
          (column) => column.colId === columnObj.colId
        );
        if (matchingColumn) {
          return {
            ...columnObj,
            columnIndex: matchingColumn.columnIndex,
            columnVisible: matchingColumn.columnVisible,
            width: matchingColumn.width,
            rowGroupIndex:matchingColumn.rowGroupIndex,
            pinned:matchingColumn.pinned,
            pinnedPosition:matchingColumn.pinnedPosition, 
            sort:matchingColumn.sort,
            sortIndex:matchingColumn.sortIndex

          };
        }
        return columnObj;
      });
      // console.debug("Updated TableData  : ", tobeColumnState);
    }
    
    tobeColumnState.sort(compareByColumnIndex);
    return tobeColumnState;
  }

  const toggleAction = useCallback((id) => {
      let newActionPopUpData = actionPopUpDataRef.current?.map((item)=>{
        if (item.id === id) {
          return {
            ...item,
            label: toggleLabel(id, item.label),
          };
        }
        return item;
      })
      actionPopUpDataRef.current = newActionPopUpData;
  }, []);

  const onEditColumnButtonClick = 
    (id) => {
      let toggleRequired = false;
      switch (id) {
        case 1:
          setShowModal(!showModal);
          break;
        case 2:
          setShowGroupingPanel((previousData) =>
            previousData === "never" ? "always" : "never"
          );
          toggleRequired = true;
          break;
        case 3:
          setHideAndShowSearchPanel((previousData) => !previousData);
          toggleRequired = true;
          break;
        case 4:
            setShowFiltering((previousData) => !previousData);
            toggleRequired = true;
            break;
        case 5:
          setGroupFooterVisible((previousData) => !previousData);
          toggleRequired = true;
          break;
        case 6:
            setSummaryFooterVisible((previousData) => !previousData);
            toggleRequired = true;
            break;
        case 7:
            setIsGroupingExpandable((previousData) => !previousData);
            toggleRequired = true;
            break;
        case 8:
          setMultiLineSelection((previousData) =>
            previousData === "single" ? "multiple" : "single"
          );
          break;
        case 9:
          setShowCustomizeModal((previousData) => !previousData);
          break;
        case 10:
          handleCustomizeLayout();
          break;
        case 11:
          onRestoreDefaultLayout();
          break;
        case 12:
          onRestoreCustomizeLayout();
          break;
        default:
          break;
      }
      if (toggleRequired) {
        setTimeout(() => {
          toggleAction(id);
        }, 1000);
      }
    };

  const toggleLabel = useCallback((id, label) => {
    const isShowLabel = label.includes("Show");
    const isDisableLabel = label.includes("Enable");

    if (isShowLabel || isDisableLabel) {
      return label.replace("Show", "Hide").replace("Enable", "Disable");
    } else {
      return label.replace("Hide", "Show").replace("Disable", "Enable");
    }
  }, []);


  return (
    <>
      <div>
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-end mb-5">
          {hideAndShowSearchPanel ? (
            <SearchInputDev
              gridRef={gridRef}
              type={tabId}
              className="col-md-5 mb-md-0 mb-3 pl-0"
              screen={"PoAndStock"}
            />
          ):( <div className={"col-md-5 mb-md-0 mb-3 pl-0"} style={{ position: "relative", display: "inline-block" }}></div>)}

          <div className="icon me-5 d-flex gap-5 pr-5">
            {customizeGridEnabled ? (
              <Tippy
                ref={tippyRef}
                content={dropDownContent}
                visible={visible}
                onClickOutside={hide}
                allowHTML={true}
                arrow={false}
                appendTo={document.body}
                interactive={true}
                placement="bottom"
              >
                <a
                  href="#!"
                  className="btn btn-light btn-active-light-primary btn-sm d-flex align-items-center justify-content-center"
                  onClick={onTippyClick}
                >
                  {t("Options")}
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr072.svg"
                    className="svg-icon-5 m-0"
                  />
                </a>
              </Tippy>
            ) : (
              ""
            )}
          {/* <DevExport
          gridRef={gridRef}
          apiData={tabSqlData}
          columnDefs={columnDefs}
          backendDef={newTabStorageData}
          file={fileName}
        /> */}
          </div>
        </div>
        

        <div className="ag-theme-alpine" style={{ height: 520, width: "100%" }}>
          {isLoadingData ? (
            <CustomLoadingCellRender />
          ) : (
               <DataGridWrapper
                gridRef={gridRef}
                onGridReady={onGridReady}
                rowData={tabSqlData}
                columnDefs={columnDefs}
                pagination={true}
                paginationPageSize={50}
                paginationPageSizeSelector={paginationPageSizeSelector}
                rowGroupPanelShow={customizeGridEnabled?showGroupingPanel==="always":null}
                groupFooterVisible={groupFooterVisible}
                isGroupingExpandable={isGroupingExpandable}
                summaryFooterVisible={summaryFooterVisible}
                height="520"
                isFilterRow={showFiltering}
                isColumnChooserEnabled={true}
                isColumnFixingEnabled={true}
                fileName={fileName}
                exportEnabled={true}
                fontSizeForPdfExport={7}

              />
          )}
        </div>

        {renderModal(
          showModal,
          "editColumns",
          t("Rename Columns"),
          closeEditModal,
          getCurrentColumnData(),
          tabStorage,
          tabSqlName,
          handleEdit,
          ""
        )}
        {renderModal(
          showCustomizeModal,
          "customizeColumns",
          t("Customized Numeric Formatting"),
          closeEditCustomizeModal,
          getCurrentColumnData()?.filter((item) => item?.columnType == "Numeric"),
          tabStorage,
          tabSqlName,
          handleEditCustomizeColumn,
          ""
        )}
      </div>
    </>
  );
};

export default DevDynamicGridTable;




    
